import "core-js/modules/es.object.to-string.js";
import ajax from '@/utils/ajax';
import cache from '@/utils/cache';
import Tool from '@/utils/tool';
export function doLogin(data) {
  return ajax({
    url: '/login',
    method: 'post',
    data: data
  });
}
export function logout(data) {
  return ajax({
    url: '/logout',
    method: 'post',
    data: data
  });
}
export function getUserInfo() {
  var token = cache.getTenantCode();

  if (token) {
    return ajax({
      url: '/getUserInfo',
      method: 'get'
    });
  }

  return Promise.resolve(Tool.buildError('999', '您的登录已过期'));
}
export function getLoginBg(params) {
  return ajax({
    url: '/getLoginPlatformBg',
    hideError: true,
    params: params
  });
}
export function getPlatformName(params) {
  return ajax({
    url: '/getLoginPlatformName',
    hideError: true,
    params: params
  });
}
export function getPlatformInfo(params) {
  return ajax({
    url: '/getLoginPlatformInfo',
    hideError: true,
    params: params
  });
}