import _slicedToArray from "/home/jenkins/agent/workspace/digitalnmn8f/digital-guv-web/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import RouterMenu from '@/layout/components/RouterMenu';
import DateSelect from '@/components/commons/DateSelect.vue';
import BusinessSelect from '@/components/commons/BusinessSelect.vue';
import Normal from './normal/index';
import Pk from './pk/index';
import dayjs from 'dayjs';
export default {
  name: 'Business',
  components: {
    DateSelect: DateSelect,
    RouterMenu: RouterMenu,
    BusinessSelect: BusinessSelect,
    Normal: Normal,
    Pk: Pk
  },
  data: function data() {
    return {
      dates: [],
      tradeCodes: [],
      pkTradeCodes: []
    };
  },
  watch: {
    dates: function dates() {
      this.onSearch();
    },
    tradeCodes: function tradeCodes() {
      this.onSearch();
    },
    pkTradeCodes: function pkTradeCodes() {
      if (this.pkTradeCodes.toString() === this.tradeCodes.toString()) {
        this.toast.warn('PK商圈不可以相同');
        this.pkTradeCodes = [];
      } else {
        this.onSearch();
      }
    }
  },
  computed: {
    currentComponent: function currentComponent() {
      if (this.pkTradeCodes && this.pkTradeCodes.length > 0 && this.pkTradeCodes.toString() !== this.tradeCodes.toString()) {
        return 'Pk';
      } else {
        return 'Normal';
      }
    },
    isPkMode: function isPkMode() {
      return this.pkTradeCodes && this.pkTradeCodes.length > 0 && this.pkTradeCodes.toString() !== this.tradeCodes.toString();
    }
  },
  methods: {
    onSearch: function onSearch() {
      var _this = this;

      this.$nextTick(function () {
        if (_this.isPkMode) {
          _this.onPkSearch();
        } else {
          _this.onNormalSearch();
        }
      });
    },
    onNormalSearch: function onNormalSearch() {
      if (this.dates.length > 0 && this.tradeCodes.length > 0) {
        var _this$dates = _slicedToArray(this.dates, 2),
            year = _this$dates[0],
            month = _this$dates[1];

        var params = {
          timeline: dayjs("".concat(year, "-").concat(month)).format('YYYY-MM-DD 00:00:00'),
          tradeAreaCode: this.tradeCodes[1]
        };
        this.$refs.component && this.$refs.component.search(params);
      }
    },
    onPkSearch: function onPkSearch() {
      if (this.dates.length > 0 && this.tradeCodes.length > 0 && this.pkTradeCodes.length > 0) {
        var _this$dates2 = _slicedToArray(this.dates, 2),
            year = _this$dates2[0],
            month = _this$dates2[1];

        var params = {
          timeline: dayjs("".concat(year, "-").concat(month)).format('YYYY-MM-DD 00:00:00'),
          tradeAreaCode: this.tradeCodes[1],
          targetTradeAreaCode: [this.pkTradeCodes[1]]
        };
        this.$refs.component && this.$refs.component.search(params);
      }
    }
  },
  mounted: function mounted() {}
};