import ajax from '@/utils/ajax';
/* 全市消费转化率top5 */

export function queryCityRate(data) {
  return ajax({
    url: '/digital/city/queryCityRate',
    method: 'post',
    data: data
  });
}
/* 城市行业销售top5 */

export function queryBizSales(data) {
  return ajax({
    url: '/digital/city/queryBizSales',
    method: 'post',
    data: data
  });
}
/* 全市监测商圈消费占比 */

export function queryCityTrade(data) {
  return ajax({
    url: '/digital/city/queryCityTrade',
    method: 'post',
    data: data
  });
}
/* 全市消费排名 */

export function querySales(data) {
  return ajax({
    url: '/digital/city/querySales',
    method: 'post',
    data: data
  });
}
/* 全市监测商圈消费占比 */

export function queryLevel(data) {
  return ajax({
    url: '/digital/city/queryLevel',
    method: 'post',
    data: data
  });
}
/* 各城市的消费粘性行业(输入输出)TOP5 */

export function queryConsumeBiz(data) {
  return ajax({
    url: '/digital/consume/queryConsumeBiz',
    method: 'post',
    data: data
  });
}
/* 各城市的消费粘性(输入输出)TOP5 */

export function queryConsumeCity(data) {
  return ajax({
    url: '/digital/consume/queryConsumeCity',
    method: 'post',
    data: data
  });
}
/* 销售额 */

export function queryConsumeTime(data) {
  return ajax({
    url: '/digital/consume/queryConsumeTime',
    method: 'post',
    data: data
  });
}
/* 查询当前用户所能看到的商圈信息 */

export function queryTrade() {
  return ajax({
    url: '/digital/common/queryTrade',
    method: 'post'
  });
}
/* 地图中间当前省份基本信息展示 */

export function queryBaseInfo() {
  return ajax({
    url: '/digital/city/queryBaseInfo',
    method: 'post'
  });
}
/* 地图中间各行政区基本信息展示 */

export function queryBaseCityInfo() {
  return ajax({
    url: '/digital/city/queryBaseCityInfo',
    method: 'post'
  });
}