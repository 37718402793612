var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-chart-box" }, [
    _c("div", { staticClass: "tabs" }, [
      _c("div", { staticClass: "item area" }, [
        _c("div", { staticClass: "title" }, [_vm._v("综合评分")]),
        _c("div", { staticClass: "text" }, [
          _vm._v(" " + _vm._s(_vm.results.compositeScore) + " "),
        ]),
      ]),
      _c("div", { staticClass: "item tradeCount" }, [
        _c("div", { staticClass: "title" }, [_vm._v("销售评分")]),
        _c("div", { staticClass: "text" }, [
          _vm._v(_vm._s(_vm.results.salesScore)),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "tabs" }, [
      _c("div", { staticClass: "item streetInfo" }, [
        _c("div", { staticClass: "title" }, [_vm._v("消费活跃度")]),
        _c("div", { staticClass: "text" }, [
          _vm._v(_vm._s(_vm.results.consumeScore)),
        ]),
      ]),
      _c("div", { staticClass: "item trafficInfo" }, [
        _c("div", { staticClass: "title" }, [_vm._v("交通便利度")]),
        _c("div", { staticClass: "text" }, [
          _vm._v(_vm._s(_vm.results.convience)),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }