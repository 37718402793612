//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { queryLevel } from '@/api/city';
import * as echarts from 'echarts';
import 'echarts-liquidfill'; // 全市消费占比

export default {
  name: 'ConsumeRatio',
  data: function data() {
    return {
      queryLevelData: {}
    };
  },
  props: {
    timeline: {
      type: String,
      default: ''
    }
  },
  watch: {
    timeline: function timeline() {
      this.fetchData();
    }
  },
  methods: {
    fontSize: function fontSize(res) {
      var docEl = document.documentElement,
          clientWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      if (!clientWidth) return;
      var fontSize = 100 * (clientWidth / 1920);
      return res * fontSize;
    },
    init: function init() {
      var myChart1 = echarts.init(document.getElementById('main1'));
      var myChart2 = echarts.init(document.getElementById('main2'));
      var myChart3 = echarts.init(document.getElementById('main3'));
      var option1 = {
        graphic: [{
          type: 'text',
          left: 'center',
          bottom: 0,
          style: {
            fill: 'rgba(146, 185, 251, 1)',
            text: '高消费',
            fontSize: '1.4vh'
          }
        }],
        series: [{
          type: 'liquidFill',
          data: [this.queryLevelData.heightAmount / 100],
          radius: '80%',
          // 水球颜色
          color: [{
            type: 'linear',
            x: 0,
            y: 1,
            x2: 0,
            y2: 0,
            colorStops: [{
              offset: 1,
              color: ['rgba(29, 67, 243, 1)'] // 0% 处的颜色

            }, {
              offset: 0,
              color: ['rgba(67, 102, 243, 1)'] // 100% 处的颜色

            }],
            global: false // 缺省为 false

          }],
          center: ['50%', '40%'],
          label: {
            formatter: '{a}\n{b}\nValue: {c}',
            baseline: 'bottom',
            normal: {
              textStyle: {
                color: 'rgba(255, 255, 255, 1)',
                fontWeight: 400,
                fontSize: this.fontSize(0.25)
              }
            }
          },
          backgroundStyle: {
            color: {
              type: 'radial',
              x: 0.5,
              y: 0.5,
              r: 0.55,
              colorStops: [{
                offset: 0.5,
                color: 'rgb(11,27,52)' // 0% 处的颜色

              }, {
                offset: 0.75,
                color: 'rgb(27,41,83)' // 100% 处的颜色

              }, {
                offset: 0.95,
                color: 'rgb(53,63,135)' // 100% 处的颜色

              }],
              globalCoord: false // 缺省为 false

            }
          },
          outline: {
            show: false
          }
        }]
      };
      var option2 = {
        graphic: [{
          type: 'text',
          left: 'center',
          bottom: 0,
          style: {
            fill: 'rgba(146, 185, 251, 1)',
            text: '中消费',
            fontSize: '1.4vh'
          }
        }],
        series: [{
          type: 'liquidFill',
          data: [this.queryLevelData.middleAmount / 100],
          radius: '80%',
          // 水球颜色
          color: [{
            type: 'linear',
            x: 0,
            y: 1,
            x2: 0,
            y2: 0,
            colorStops: [{
              offset: 1,
              color: ['rgba(129, 213, 158, 1)'] // 0% 处的颜色

            }, {
              offset: 0,
              color: ['rgba(49, 155, 136, 1)'] // 100% 处的颜色

            }],
            global: false // 缺省为 false

          }],
          center: ['50%', '40%'],
          label: {
            normal: {
              textStyle: {
                color: 'rgba(255, 255, 255, 1)',
                fontWeight: 400,
                fontSize: this.fontSize(0.25)
              }
            }
          },
          backgroundStyle: {
            color: {
              type: 'radial',
              x: 0.5,
              y: 0.5,
              r: 0.55,
              colorStops: [{
                offset: 0.5,
                color: 'rgb(11,27,52)' // 0% 处的颜色

              }, {
                offset: 0.75,
                color: 'rgb(27,41,83)' // 100% 处的颜色

              }, {
                offset: 0.95,
                color: 'rgb(53,63,135)' // 100% 处的颜色

              }],
              globalCoord: false // 缺省为 false

            }
          },
          outline: {
            show: false
          }
        }]
      };
      var option3 = {
        graphic: [{
          type: 'text',
          left: 'center',
          bottom: 0,
          style: {
            fill: 'rgba(146, 185, 251, 1)',
            text: '低消费',
            fontSize: '1.4vh'
          }
        }],
        series: [{
          type: 'liquidFill',
          data: [this.queryLevelData.lowAmount / 100],
          radius: '80%',
          // 水球颜色
          // color: ['rgba(255, 173, 86, 0.79)', 'rgba(247, 255, 160, 1)'],
          color: [{
            type: 'linear',
            x: 0,
            y: 1,
            x2: 0,
            y2: 0,
            colorStops: [{
              offset: 1,
              color: ['rgba(247, 255, 160, 1)'] // 0% 处的颜色

            }, {
              offset: 0,
              color: ['rgba(255, 173, 86, 0.79)'] // 100% 处的颜色

            }],
            global: false // 缺省为 false

          }],
          center: ['50%', '40%'],
          label: {
            normal: {
              textStyle: {
                color: 'rgba(255, 255, 255, 1)',
                fontWeight: 400,
                fontSize: this.fontSize(0.25)
              }
            }
          },
          backgroundStyle: {
            color: {
              type: 'radial',
              x: 0.5,
              y: 0.5,
              r: 0.55,
              colorStops: [{
                offset: 0.5,
                color: 'rgb(11,27,52)' // 0% 处的颜色

              }, {
                offset: 0.75,
                color: 'rgb(27,41,83)' // 100% 处的颜色

              }, {
                offset: 0.95,
                color: 'rgb(53,63,135)' // 100% 处的颜色

              }],
              globalCoord: false // 缺省为 false

            }
          },
          outline: {
            show: false
          }
        }]
      }; // 使用刚指定的配置项和数据显示图表。

      myChart1.setOption(option1);
      myChart2.setOption(option2);
      myChart3.setOption(option3);
    },
    fetchData: function fetchData() {
      var _this = this;

      if (!this.timeline) {
        return;
      }

      queryLevel({
        timeline: this.timeline
      }).then(function (res) {
        _this.queryLevelData = res;

        _this.init();

        console.log('res_queryLevel:', res);
      }).catch(function (err) {
        console.log('queryLevel_Err==>', err); // this.$Toast.Message.error(error);
      });
    }
  },
  created: function created() {},
  mounted: function mounted() {}
};