var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-container" }, [
    _c(
      "div",
      { staticClass: "flex items-center justify-between page-header" },
      [
        _c(
          "div",
          { staticClass: "flex items-center" },
          [
            _c("DateSelect", {
              attrs: { multiple: true },
              model: {
                value: _vm.form.dates,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "dates", $$v)
                },
                expression: "form.dates",
              },
            }),
            _c("IndustrySelect", {
              staticClass: "mx-3",
              attrs: { multiple: true },
              model: {
                value: _vm.form.bizTypeCodeList,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "bizTypeCodeList", $$v)
                },
                expression: "form.bizTypeCodeList",
              },
            }),
            _c(
              "el-button",
              {
                staticClass: "w-122 el-button-mt",
                on: {
                  click: function ($event) {
                    return _vm.onSearch()
                  },
                },
              },
              [_vm._v("查询")]
            ),
          ],
          1
        ),
        _c("RouterMenu"),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "page-content" },
      [
        _c(
          "el-row",
          { staticStyle: { width: "100%" }, attrs: { gutter: 15 } },
          [
            _c(
              "el-col",
              { staticClass: "flex flex-col", attrs: { span: 12 } },
              [
                _c("TrendIndustry", { ref: "c1" }),
                _c("TrendActive", { ref: "c2" }),
              ],
              1
            ),
            _c(
              "el-col",
              { staticClass: "flex flex-col", attrs: { span: 12 } },
              [
                _c("TrendResident", { ref: "c3" }),
                _c("TrendForeign", { ref: "c4" }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }