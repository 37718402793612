var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-chart-box" }, [
    _c("img", {
      staticClass: "title_img",
      attrs: { src: require("@/assets/images/trendActiveTitle.png"), alt: "" },
    }),
    _vm._m(0),
    _c("div", {
      ref: "trendActive",
      staticClass: "chart",
      style: { visibility: _vm.isShowChart ? "visible" : "hidden" },
    }),
    _c(
      "div",
      {
        staticClass: "chart-empty-title",
        style: { visibility: _vm.isShowChart ? "hidden" : "visible" },
      },
      [_vm._v("暂无数据")]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "legends" }, [
      _c("div", { staticClass: "legend" }, [
        _c("span", {
          staticClass: "line",
          staticStyle: { background: "#DC443F" },
        }),
        _c("span", [_vm._v("常住白天活跃")]),
      ]),
      _c("div", { staticClass: "legend" }, [
        _c("span", {
          staticClass: "line",
          staticStyle: { background: "#1CC4A7" },
        }),
        _c("span", [_vm._v("常住夜间活跃")]),
      ]),
      _c("div", { staticClass: "legend" }, [
        _c("span", {
          staticClass: "line",
          staticStyle: { background: "#2F70D6" },
        }),
        _c("span", [_vm._v("流动日间人口")]),
      ]),
      _c("div", { staticClass: "legend" }, [
        _c("span", {
          staticClass: "line",
          staticStyle: { background: "#FFC72F" },
        }),
        _c("span", [_vm._v("流动夜间人口")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }