import _objectSpread from "/home/jenkins/agent/workspace/digitalnmn8f/digital-guv-web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
import { mapActions } from 'vuex';
import cache from '@/utils/cache';
export default {
  name: 'Header',
  components: {},
  data: function data() {
    return {
      platformName: cache.getPlatformName(),
      logoutLoading: false
    };
  },
  methods: _objectSpread(_objectSpread({}, mapActions(['handleLogout'])), {}, {
    onLogout: function onLogout() {
      var _this = this;

      this.logoutLoading = true;
      this.handleLogout().then(function () {
        _this.logoutLoading = false;

        _this.$router.push({
          name: 'redirectLogin'
        });
      }).catch(function () {
        _this.logoutLoading = false;
      });
    },
    onHome: function onHome() {
      this.$router.push('/app/home');
    }
  }),
  created: function created() {},
  mounted: function mounted() {}
};