import _slicedToArray from "/home/jenkins/agent/workspace/digitalnmn8f/digital-guv-web/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import DateSelect from '@/components/commons/DateSelect.vue';
import AreaSelect from '@/components/commons/AreaSelect.vue';
import RouterMenu from '@/layout/components/RouterMenu';
import AreaCategories from './components/AreaCates.vue';
import AreaPopulation from './components/AreaPopulation.vue';
import AreaConvert from './components/AreaConvert.vue';
import AreaSales from './components/AreaSales.vue'; // import AreaMap from './components/AreaMap.vue';

import AreaMap from './components/AreaGDMap.vue';
import AreaBasicInformation from './components/AreaBasicInformation.vue';
import dayjs from 'dayjs';
export default {
  name: 'Area',
  components: {
    DateSelect: DateSelect,
    AreaSelect: AreaSelect,
    RouterMenu: RouterMenu,
    AreaPopulation: AreaPopulation,
    AreaCategories: AreaCategories,
    AreaConvert: AreaConvert,
    AreaSales: AreaSales,
    AreaMap: AreaMap,
    AreaBasicInformation: AreaBasicInformation
  },
  data: function data() {
    return {
      form: {
        dates: [],
        area: {}
      }
    };
  },
  watch: {
    form: {
      deep: true,
      handler: function handler() {
        this.onSearch();
      }
    }
  },
  methods: {
    onSearch: function onSearch() {
      if (this.form.dates.length > 0 && !!"".concat(this.form.area.areaCode)) {
        var _this$form$dates = _slicedToArray(this.form.dates, 2),
            year = _this$form$dates[0],
            month = _this$form$dates[1];

        var params = {
          timeline: dayjs("".concat(year, "-").concat(month)).format('YYYY-MM-DD 00:00:00'),
          areaCode: this.form.area.areaCode
        };
        console.log('发起搜索', params); // TODO:组装好的参数，通过子组件来调用, 子组件的方法需暴露search方法进行接收，然后触发接口调用，请勿在mounted进行接口调用，触发时机由外层容器控制

        this.$refs.ref1.search && this.$refs.ref1.search(params);
        this.$refs.ref2.search && this.$refs.ref2.search(params);
        this.$refs.ref3.search && this.$refs.ref3.search(params);
        this.$refs.ref4.search && this.$refs.ref4.search(params); // this.$refs.ref5.search && this.$refs.ref5.search(params);

        this.$refs.ref6.search && this.$refs.ref6.search(params);
      }
    },
    // 区域发生变化，更新行政区地图
    onAreaChange: function onAreaChange() {
      this.$refs.ref5.refresh && this.$refs.ref5.refresh({
        area: this.form.area
      });
    }
  },
  created: function created() {},
  mounted: function mounted() {}
};