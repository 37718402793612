import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import BusinessMap from './components/BusinessMap.vue';
import BusinessSource from './components/BusinessSource.vue';
import BusinessMoneyRatio from './components/BusinessMoneyRatio.vue';
import BusinessPeopleTrend from './components/BusinessPeopleTrend.vue';
import BusinessTags from './components/BusinessTags.vue';
import BusinessActive from './components/BusinessActive.vue';
import BusinessTraffic from './components/BusinessTraffic.vue';
import BusinessDynamicShare from './components/BusinessDynamicShare.vue';
import BusinessSalesTrend from './components/BusinessSalesTrend.vue';
export default {
  name: 'BusinessPK',
  components: {
    BusinessMap: BusinessMap,
    BusinessSource: BusinessSource,
    BusinessMoneyRatio: BusinessMoneyRatio,
    BusinessPeopleTrend: BusinessPeopleTrend,
    BusinessTags: BusinessTags,
    BusinessActive: BusinessActive,
    BusinessTraffic: BusinessTraffic,
    BusinessDynamicShare: BusinessDynamicShare,
    BusinessSalesTrend: BusinessSalesTrend
  },
  data: function data() {
    return {
      groupNames: []
    };
  },
  methods: {
    search: function search(params) {
      this.$refs.ref1.search && this.$refs.ref1.search(params);
      this.$refs.ref2.search && this.$refs.ref2.search(params);
      this.$refs.ref3.search && this.$refs.ref3.search(params);
      this.$refs.ref4.search && this.$refs.ref4.search(params);
      this.$refs.ref5.search && this.$refs.ref5.search(params);
      this.$refs.ref6.search && this.$refs.ref6.search(params);
      this.$refs.ref7.search && this.$refs.ref7.search(params);
      this.$refs.ref8.search && this.$refs.ref8.search(params);
      this.$refs.ref9.search && this.$refs.ref9.search(params); // if (this.form.dates.length > 0 && this.form.tradeCodes.length > 0) {
      //   const [year, month] = this.form.dates;
      //   const params = {
      //     timeline: dayjs(`${year}-${month}`).format('YYYY-MM-DD 00:00:00'),
      //     tradeAreaCode: this.form.tradeCodes[1]
      //   };
      // // TODO:组装好的参数，通过子组件来调用, 子组件的方法需暴露search方法进行接收，然后触发接口调用，请勿在mounted进行接口调用，触发时机由外层容器控制
      // // this.$refs.ref1.search && this.$refs.ref1.search(params);
      // // this.$refs.ref2.search && this.$refs.ref2.search(params);
      // // this.$refs.ref3.search && this.$refs.ref3.search(params);
      // // this.$refs.ref4.search && this.$refs.ref4.search(params);
      // // this.$refs.ref5.search && this.$refs.ref5.search(params);
      // // this.$refs.ref6.search && this.$refs.ref6.search(params);
      // }
    }
  }
};