import _slicedToArray from "/home/jenkins/agent/workspace/digitalnmn8f/digital-guv-web/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import RouterMenu from '@/layout/components/RouterMenu';
import TrendIndustry from './components/TrendIndustry.vue';
import TrendActive from './components/TrendActive.vue';
import TrendForeign from './components/TrendForeign.vue';
import TrendResident from './components/TrendResident.vue';
import IndustrySelect from '@/components/commons/IndustrySelect.vue';
import DateSelect from '@/components/commons/DateSelect.vue';
import dayjs from 'dayjs';
export default {
  name: 'Trend',
  components: {
    RouterMenu: RouterMenu,
    TrendIndustry: TrendIndustry,
    TrendActive: TrendActive,
    TrendForeign: TrendForeign,
    TrendResident: TrendResident,
    DateSelect: DateSelect,
    IndustrySelect: IndustrySelect
  },
  data: function data() {
    return {
      form: {
        dates: [],
        bizTypeCodeList: [] // 不传则默认是全部行业

      }
    };
  },
  methods: {
    onSearch: function onSearch() {
      var timelineList = this.form.dates.map(function (o) {
        var _o = _slicedToArray(o, 2),
            year = _o[0],
            month = _o[1];

        return dayjs("".concat(year, "-").concat(month)).format('YYYY-MM-DD 00:00:00');
      });
      var params = {};

      if (timelineList && timelineList.length > 0) {
        params.timelineList = timelineList;
      }

      if (this.form.bizTypeCodeList && this.form.bizTypeCodeList.length > 0) {
        params.bizTypeCodeList = this.form.bizTypeCodeList;
      }

      console.log('趋势监测发起搜索', params); // TODO:组装好的参数，通过子组件来调用,search为子组件的方法，mounted也需要在mounted主动触发一次search方法

      this.$refs.c1.search(params);
      this.$refs.c2.search(params);
      this.$refs.c3.search(params);
      this.$refs.c4.search(params);
    }
  },
  created: function created() {},
  mounted: function mounted() {}
};