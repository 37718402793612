var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-chart-box home-map" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.mapLoading,
            expression: "mapLoading",
          },
        ],
        attrs: { id: "container" },
      },
      [
        _c("el-switch", {
          staticClass: "switch",
          attrs: { "active-text": "全国", "inactive-text": "城市" },
          on: { change: _vm.onModeChange },
          model: {
            value: _vm.isModeCountry,
            callback: function ($$v) {
              _vm.isModeCountry = $$v
            },
            expression: "isModeCountry",
          },
        }),
        _c(
          "div",
          {
            staticClass: "hover",
            style: {
              top: _vm.layerY + "px",
              left: _vm.layerX + "px",
              display: _vm.isMarkerShow ? "flex" : "none",
            },
          },
          [
            _c("div", { staticClass: "info" }, [
              _c("div", { staticClass: "tradeAreaName" }, [
                _vm._v(_vm._s(_vm.tradeAreaName)),
              ]),
              _c("div", { staticClass: "data list" }, [
                _c("div", { staticClass: "item" }, [
                  _vm._v(" 总面积： "),
                  _c("div", { staticClass: "text" }, [
                    _vm._v(_vm._s(_vm.districtInfo.area)),
                  ]),
                ]),
                _c("div", { staticClass: "item" }, [
                  _vm._v(" 常住人口： "),
                  _c("div", { staticClass: "text" }, [
                    _vm._v(_vm._s(_vm.districtInfo.people)),
                  ]),
                ]),
                _c("div", { staticClass: "item" }, [
                  _vm._v(" 商圈监测数量： "),
                  _c("div", { staticClass: "text" }, [
                    _vm._v(_vm._s(_vm.districtInfo.tradeCount)),
                  ]),
                ]),
              ]),
            ]),
          ]
        ),
      ],
      1
    ),
    _c("div", { staticClass: "tabs" }, [
      _c("div", { staticClass: "item area" }, [
        _c("div", { staticClass: "title" }, [_vm._v("全市总面积(km²)")]),
        _c("div", { staticClass: "text" }, [
          _vm._v(_vm._s(_vm.info && _vm.info.area)),
        ]),
      ]),
      _c("div", { staticClass: "item people" }, [
        _c("div", { staticClass: "title" }, [_vm._v("全市常住人口(万人)")]),
        _c("div", { staticClass: "text" }, [
          _vm._v(_vm._s(_vm.info && _vm.info.people)),
        ]),
      ]),
      _c("div", { staticClass: "item tradeCount" }, [
        _c("div", { staticClass: "title" }, [_vm._v("全市商圈监测数量")]),
        _c("div", { staticClass: "text" }, [
          _vm._v(_vm._s(_vm.info && _vm.info.tradeCount)),
        ]),
      ]),
      _c("div", { staticClass: "item tradeBodyCount" }, [
        _c("div", { staticClass: "title" }, [_vm._v("全市商业体监测数量")]),
        _c("div", { staticClass: "text" }, [
          _vm._v(_vm._s(_vm.info && _vm.info.tradeBodyCount)),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }