import _slicedToArray from "/home/jenkins/agent/workspace/digitalnmn8f/digital-guv-web/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import "core-js/modules/es.array.concat.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import RouterMenu from '@/layout/components/RouterMenu';
import ConsumeRanking from '@/views/home/components/ConsumeRanking';
import BusinessRatio from '@/views/home/components/BusinessRatio';
import ConsumeRatio from '@/views/home/components/ConsumeRatio';
import ConsumeStickiness from '@/views/home/components/ConsumeStickiness';
import ConversionRate from '@/views/home/components/ConversionRate';
import HomeMap from '@/views/home/components/HomeMap';
import HomeSales from '@/views/home/components/HomeSales';
import TypeRatio from '@/views/home/components/TypeRatio';
import DateSelect from '@/components/commons/DateSelect';
import dayjs from 'dayjs';
export default {
  name: 'Home',
  components: {
    DateSelect: DateSelect,
    RouterMenu: RouterMenu,
    ConsumeRanking: ConsumeRanking,
    BusinessRatio: BusinessRatio,
    ConsumeRatio: ConsumeRatio,
    ConsumeStickiness: ConsumeStickiness,
    ConversionRate: ConversionRate,
    HomeMap: HomeMap,
    HomeSales: HomeSales,
    TypeRatio: TypeRatio
  },
  data: function data() {
    return {
      dates: []
    };
  },
  computed: {
    timeline: function timeline() {
      if (this.dates && this.dates.length > 0) {
        var _this$dates = _slicedToArray(this.dates, 2),
            year = _this$dates[0],
            month = _this$dates[1];

        return dayjs("".concat(year, "-").concat(month)).format('YYYY-MM-DD 00:00:00');
      } else {
        return '';
      }
    }
  },
  methods: {},
  created: function created() {},
  mounted: function mounted() {}
};