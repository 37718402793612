var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-chart-box" }, [
    _c("img", {
      staticClass: "ConversionRateTitle",
      attrs: {
        src: require("@/assets/images/ConversionRateTitle.png"),
        alt: "",
      },
    }),
    _c("div", { staticClass: "chart" }, [
      _c("div", {
        ref: "ConversionRate",
        staticStyle: { width: "70%", height: "100%" },
      }),
      _c("img", {
        staticClass: "coneImg",
        attrs: { src: require("@/assets/images/coneImg.png"), alt: "" },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }