import ajax from '@/utils/ajax'; // 获取时间线列表

export function getTimeline(params) {
  return ajax({
    url: '/digital/common/queryTimeline',
    method: 'post',
    params: params
  });
} // 趋势模块行业列表

export function getIndustries() {
  return ajax({
    url: '/digital/trend/queryBizList',
    method: 'post'
  });
} // 查询当前用户所能看到的区域

export function getAreas() {
  return ajax({
    url: '/digital/common/queryArea',
    method: 'post'
  });
} // 查询当前用户所能看到的商圈信息

export function getBusiness() {
  return ajax({
    url: '/digital/common/queryTrade',
    method: 'post'
  });
} // 查询xxx区下面的街道列表

export function getStreet(data) {
  return ajax({
    url: '/digital/facilities/getStreetInfo',
    data: data,
    method: 'post'
  });
}