var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isShow,
          expression: "isShow",
        },
      ],
      staticClass: "page-chart-box",
    },
    [
      _c("img", {
        staticClass: "title_img",
        attrs: {
          src: require("@/assets/images/convenientInfoTitle.png"),
          alt: "",
        },
      }),
      _c(
        "div",
        { staticClass: "box" },
        _vm._l(_vm.showedList, function (item, index) {
          return _c(
            "div",
            {
              key: item,
              class: ["item", _vm.isExceed && index === 14 ? "more" : ""],
            },
            [
              _vm.isExceed && index === 14
                ? _c(
                    "el-popover",
                    {
                      attrs: {
                        placement: "left",
                        width: "260",
                        trigger: "hover",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "box pop-box" },
                        _vm._l(_vm.list, function (o) {
                          return _c("div", { key: o, staticClass: "item" }, [
                            _vm._v(" " + _vm._s(o) + " "),
                          ])
                        }),
                        0
                      ),
                      _c(
                        "span",
                        { attrs: { slot: "reference" }, slot: "reference" },
                        [_vm._v(_vm._s(item))]
                      ),
                    ]
                  )
                : _c("span", [_vm._v(_vm._s(item))]),
            ],
            1
          )
        }),
        0
      ),
      _vm.area !== 0
        ? _c("div", { staticClass: "area-box" }, [
            _c("span", [_vm._v("面积")]),
            _c("span", [_vm._v(_vm._s(_vm.area) + "平方公里")]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }