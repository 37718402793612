import "core-js/modules/es.function.name.js";
import Vue from 'vue';
import Router from 'vue-router';
import routes from './routers';
import store from '@/store';
import cache from '@/utils/cache';
Vue.use(Router);
var router = new Router({
  routes: routes,
  mode: 'history'
});
var originalPush = Router.prototype.push;

Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(function (err) {
    return err;
  });
};

var HOME_PAGE_NAME = 'Home';
var LOGIN_PAGE_NAME = 'login';
router.beforeEach(function (to, from, next) {
  var token = cache.getToken();

  if (!token && to.name !== LOGIN_PAGE_NAME && to.name !== 'redirectLogin' && to.name !== 'login') {
    // 未登录且要跳转的页面不是登录页
    cache.clear();
    next({
      name: 'redirectLogin'
    });
  } else if (!token && to.name === LOGIN_PAGE_NAME) {
    // 未登陆且要跳转的页面是登录页
    next(); // 跳转
  } else if (token && to.name === LOGIN_PAGE_NAME) {
    // 已登录且要跳转的页面是登录页
    if (from && from.name) {
      next({
        name: from.name
      });
    } else {
      next({
        name: HOME_PAGE_NAME
      });
    }
  } else {
    console.log('store.state.user.hasGetInfo：', store.state.user.hasGetInfo);

    if (store.state.user.hasGetInfo) {
      next();
    } else {
      store.dispatch('getUserInfo').then(function (result) {
        next();
      }).catch(function (error) {
        if (error.code && error.code === '999') {
          store.dispatch('handleLogout').then(function (result) {
            next({
              name: 'redirectLogin'
            });
          });
        } else {
          next();
        }
      });
    }
  }
});
export default router;