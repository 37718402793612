import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
import cache from '@/utils/cache';
export default {
  name: 'Index',
  components: {},
  data: function data() {
    return {
      menus: [{
        title: cache.getUser().userCity,
        name: 'Home'
      }, // {
      //   title: '行政区',
      //   name: 'Area'
      // },
      {
        title: '商圈',
        name: 'Business'
      } // {
      //   title: '便民设施',
      //   name: 'Convenient'
      // },
      // {
      //   title: '趋势监测',
      //   name: 'Trend'
      // }
      ]
    };
  },
  computed: {
    current: function current() {
      return this.$route.name;
    }
  },
  methods: {
    onMenu: function onMenu(o) {
      if (o.name === this.current) {
        return;
      }

      this.$router.push({
        name: o.name
      });
    }
  },
  created: function created() {},
  mounted: function mounted() {}
};