var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-cascader",
    _vm._g(
      _vm._b(
        {
          attrs: {
            "collapse-tags": "",
            clearable: _vm.clearable,
            placeholder: _vm.pkMode ? "选择商圈PK" : "请选择",
            separator: "",
            options: _vm.options,
            value: _vm.value,
          },
        },
        "el-cascader",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }