var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-chart-box" }, [
    _c("img", {
      staticClass: "title_img",
      attrs: {
        src: require("@/assets/images/PKBusinessSourceTitleTitle.png"),
        alt: "",
      },
    }),
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "chart_box" }, [
        _c("div", { ref: "BusinessSource", staticClass: "chart" }),
      ]),
      _c("div", { staticClass: "chart_box" }, [
        _c("div", { ref: "BusinessSourceTwo", staticClass: "chart" }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }