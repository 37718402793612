import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import BusinessMap from './components/BusinessMap.vue';
import BusinessSource from './components/BusinessSource.vue';
import BusinessMoneyRatio from './components/BusinessMoneyRatio.vue';
import BusinessShareTop5 from './components/BusinessShareTop5.vue';
import BusinessTags from './components/BusinessTags.vue';
import BusinessInfo from './components/BusinessInfo.vue';
import BusinessBody from './components/BusinessBody.vue';
import BusinessDynamicTrend from './components/BusinessDynamicTrend.vue';
import BusinessSalesTrend from './components/BusinessSalesTrend.vue';
import BusinessMainShop from './components/BusinessMainShop.vue';
export default {
  name: 'BusinessNormal',
  components: {
    BusinessMap: BusinessMap,
    BusinessSource: BusinessSource,
    BusinessMoneyRatio: BusinessMoneyRatio,
    BusinessShareTop5: BusinessShareTop5,
    BusinessTags: BusinessTags,
    BusinessInfo: BusinessInfo,
    BusinessBody: BusinessBody,
    BusinessDynamicTrend: BusinessDynamicTrend,
    BusinessSalesTrend: BusinessSalesTrend,
    BusinessMainShop: BusinessMainShop
  },
  data: function data() {
    return {};
  },
  methods: {
    search: function search(params) {
      this.$refs.ref1.search && this.$refs.ref1.search(params);
      this.$refs.ref2.search && this.$refs.ref2.search(params);
      this.$refs.ref3.search && this.$refs.ref3.search(params);
      this.$refs.ref4.search && this.$refs.ref4.search(params);
      this.$refs.ref5.search && this.$refs.ref5.search(params);
      this.$refs.ref6.search && this.$refs.ref6.search(params);
      this.$refs.ref7.search && this.$refs.ref7.search(params);
      this.$refs.ref8.search && this.$refs.ref8.search(params);
      this.$refs.ref9.search && this.$refs.ref9.search(params);
      this.$refs.ref10.search && this.$refs.ref10.search(params);
    }
  }
};