var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-chart-box" }, [
    _c("img", {
      staticClass: "title_img",
      attrs: {
        src: require("@/assets/images/businessMoneyRatioTitle.png"),
        alt: "",
      },
    }),
    _c(
      "div",
      { staticClass: "legends" },
      _vm._l(_vm.legendData, function (val, i) {
        return _c("div", { key: i + "legend", staticClass: "legend" }, [
          _c("span", {
            staticClass: "line",
            style: { background: _vm.colorList[i] },
          }),
          _c("span", [_vm._v(_vm._s(val))]),
        ])
      }),
      0
    ),
    _c("div", { ref: "BusinessMoneyRatio", staticClass: "chart" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }