var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-chart-box AreaMap" }, [
    _c("div", {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.mapLoading,
          expression: "mapLoading",
        },
      ],
      attrs: { id: "container" },
    }),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isInited,
            expression: "isInited",
          },
        ],
        staticClass: "hover",
        attrs: { id: "infoWindow" },
      },
      [
        _vm._m(0),
        _c("div", { staticClass: "info" }, [
          _c("div", { staticClass: "title" }, [_vm._v("消费偏好")]),
          _c("div", { staticClass: "tradeAreaName" }, [
            _vm._v("(" + _vm._s(_vm.tradeAreaName) + ")"),
          ]),
          _c(
            "div",
            { staticClass: "data list" },
            _vm._l(_vm.tradeBizInfo, function (val, index) {
              return _c("div", { key: index, staticClass: "item" }, [
                _vm._v(" " + _vm._s(val.bizName) + " "),
                _c("div", { staticClass: "pencentBar" }, [
                  _c("div", {
                    staticClass: "active",
                    style: "width:" + val.percent + "%;",
                  }),
                  _c("div", { staticClass: "circle" }),
                ]),
              ])
            }),
            0
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon" }, [
      _c("img", {
        attrs: { src: require("../../../assets/images/hoverinfo.png") },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }