var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-chart-box" }, [
    _c("img", {
      staticClass: "title_img",
      attrs: { src: require("@/assets/images/pk_active.png"), alt: "" },
    }),
    _c("div", { staticClass: "chart_box" }, [
      _c("div", { ref: "BusinessInfoConvience1", staticClass: "my_chart" }),
      _c("div", { ref: "BusinessInfoConvience2", staticClass: "my_chart" }),
    ]),
    _vm.legends.length > 1
      ? _c("div", { staticClass: "legends" }, [
          _c("span", [_vm._v(_vm._s(_vm.legends[0]))]),
          _c("span", [_vm._v(_vm._s(_vm.legends[1]))]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }