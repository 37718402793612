var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-chart-box" }, [
    _c("img", {
      staticClass: "title_img",
      attrs: {
        src: require("@/assets/images/BusinessSalesTrendTitle.png"),
        alt: "",
      },
    }),
    _c("div", { staticClass: "legends" }, [
      _c("div", { staticClass: "legend" }, [
        _c("span", {
          staticClass: "line",
          staticStyle: { background: "#046EE8" },
        }),
        _c("span", [_vm._v(_vm._s(_vm.legendData[0]))]),
      ]),
      _c("div", { staticClass: "legend" }, [
        _c("span", {
          staticClass: "line",
          staticStyle: { background: "#DF413C" },
        }),
        _c("span", [_vm._v(_vm._s(_vm.legendData[1]))]),
      ]),
    ]),
    _c("div", { ref: "BusinessSalesTrend", staticClass: "chart" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }