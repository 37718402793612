var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "page-chart-box",
      style: { visibility: _vm.isShow ? "visible" : "hidden" },
    },
    [
      _c("img", {
        staticClass: "title_img",
        attrs: {
          src: require("@/assets/images/conventDistributeTitle.png"),
          alt: "",
        },
      }),
      _c("div", { ref: "barChart", staticClass: "barChart" }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }