var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-content" },
    [
      _c(
        "el-row",
        {
          staticClass: "mt-5",
          staticStyle: { width: "100%" },
          attrs: { gutter: 15 },
        },
        [
          _c(
            "el-col",
            { staticClass: "flex flex-col", attrs: { span: 7 } },
            [
              _c("BusinessSalesTrend", { ref: "ref1" }),
              _c("BusinessPeopleTrend", { ref: "ref3" }),
              _c("BusinessMoneyRatio", { ref: "ref2" }),
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "flex flex-col", attrs: { span: 12 } },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [_c("BusinessMap", { ref: "ref4" })],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 15 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [_c("BusinessTags", { ref: "ref5" })],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "flex flex-col", attrs: { span: 5 } },
            [
              _c("BusinessActive", { ref: "ref6" }),
              _c("BusinessTraffic", { ref: "ref7" }),
              _c("BusinessSource", { ref: "ref8" }),
              _c("BusinessDynamicShare", { ref: "ref9" }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }