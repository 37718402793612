var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-chart-box" },
    _vm._l(_vm.list, function (item) {
      return _c(
        "div",
        {
          key: item.code,
          staticClass: "item",
          class: { active: _vm.code === item.code },
          on: {
            click: function ($event) {
              return _vm.onItem(item)
            },
          },
        },
        [
          _c("img", {
            staticClass: "logo",
            attrs: {
              src: require("@/assets/images/convenient/" + item.name + ".png"),
              alt: "",
            },
          }),
          _vm._v(" " + _vm._s(item.name) + " "),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }