import "core-js/modules/es.string.trim.js";
import "core-js/modules/es.object.to-string.js";
import cache from '@/utils/cache';
import { doLogin, getUserInfo as _getUserInfo, logout } from '@/api/user';
import Tool from '@/utils/tool';

function buildUserInfo(commit) {
  var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var _data$lng = data.lng,
      lng = _data$lng === void 0 ? null : _data$lng,
      _data$lat = data.lat,
      lat = _data$lat === void 0 ? null : _data$lat,
      _data$nickName = data.nickName,
      nickName = _data$nickName === void 0 ? null : _data$nickName,
      _data$token = data.token,
      token = _data$token === void 0 ? null : _data$token,
      _data$tenantCode = data.tenantCode,
      tenantCode = _data$tenantCode === void 0 ? null : _data$tenantCode,
      _data$platformName = data.platformName,
      platformName = _data$platformName === void 0 ? null : _data$platformName,
      _data$userCity = data.userCity,
      userCity = _data$userCity === void 0 ? null : _data$userCity,
      _data$userCityCode = data.userCityCode,
      userCityCode = _data$userCityCode === void 0 ? null : _data$userCityCode,
      _data$loadingAreaCode = data.loadingAreaCode,
      loadingAreaCode = _data$loadingAreaCode === void 0 ? null : _data$loadingAreaCode;
  var flag = Tool.isNotNull(token);
  commit('setLng', lng);
  commit('setLat', lat);
  commit('setNickName', nickName);
  commit('setToken', token);
  commit('setHasGetInfo', flag);
  commit('setHasLogin', flag);
  commit('setPlatformName', platformName);
  commit('setUserCity', userCity);
  commit('setUserCityCode', userCityCode);
  commit('setLoadingAreaCode', loadingAreaCode);

  if (Tool.isNotNull(tenantCode)) {
    commit('setTenantCode', tenantCode);
  }
}

export default {
  state: {
    /**
     * 经度
     */
    lng: null,

    /**
     * 用户登录名
     */
    lat: null,

    /**
     * 用户昵称
     */
    nickName: '',

    /**
     * 用户token
     */
    token: cache.getToken(),

    /**
     * 是否已登录
     */
    hasLogin: false,

    /**
     * 是否已经获取过信息
     */
    hasGetInfo: false,

    /**
     * 组织编码
     */
    tenantCode: '',

    /**
     * 平台名称
     */
    platformName: '',

    /**
     * 用户所在城市名称
     */
    userCity: '',

    /**
     * 用户所在城市code
     */
    userCityCode: '',
    loadingAreaCode: ''
  },
  mutations: {
    setLng: function setLng(state, lng) {
      state.lng = lng;
    },
    setLat: function setLat(state, lat) {
      state.lat = lat;
    },
    setNickName: function setNickName(state, name) {
      state.nickName = name;
    },
    setToken: function setToken(state, token) {
      state.token = token;
      cache.setToken(token);
    },
    setHasGetInfo: function setHasGetInfo(state, hasGetInfo) {
      state.hasGetInfo = hasGetInfo;
    },
    setHasLogin: function setHasLogin(state, hasLogin) {
      state.hasLogin = hasLogin;
    },
    setTenantCode: function setTenantCode(state, tenantCode) {
      state.tenantCode = tenantCode;
      cache.setTenantCode(tenantCode);
    },
    setPlatformName: function setPlatformName(state, platformName) {
      state.platformName = platformName;
    },
    setUserCity: function setUserCity(state, userCity) {
      state.userCity = userCity;
    },
    setUserCityCode: function setUserCityCode(state, userCityCode) {
      state.userCityCode = userCityCode;
    },
    setLoadingAreaCode: function setLoadingAreaCode(state, loadingAreaCode) {
      state.loadingAreaCode = loadingAreaCode;
    }
  },
  getters: {},
  actions: {
    /**
     * 登录
     * @param commit
     * @param loginName
     * @param password
     * @param tenantCode
     * @returns {Promise<unknown>}
     */
    handleLogin: function handleLogin(_ref, _ref2) {
      var commit = _ref.commit;
      var loginName = _ref2.loginName,
          password = _ref2.password,
          _ref2$tenantCode = _ref2.tenantCode,
          tenantCode = _ref2$tenantCode === void 0 ? cache.getTenantCode() : _ref2$tenantCode;
      loginName = loginName.trim();
      return new Promise(function (resolve, reject) {
        doLogin({
          loginName: loginName,
          password: password,
          tenantCode: tenantCode
        }).then(function (result) {
          buildUserInfo(commit, result);
          cache.setUser(result);
          resolve(result.data);
        }).catch(function (err) {
          reject(err);
        });
      });
    },

    /**
     * 退出登录
     * @param commit
     * @returns {Promise<unknown>}
     */
    handleLogout: function handleLogout(_ref3) {
      var commit = _ref3.commit;
      return new Promise(function (resolve, reject) {
        logout().then(function () {
          buildUserInfo(commit);
          cache.clear();
          resolve();
        }).catch(function (err) {
          reject(err);
        });
      });
    },
    // 获取用户相关信息
    getUserInfo: function getUserInfo(_ref4) {
      var commit = _ref4.commit;
      return new Promise(function (resolve, reject) {
        try {
          _getUserInfo().then(function (result) {
            buildUserInfo(commit, result);
            resolve(result);
          }).catch(function (err) {
            reject(err);
          });
        } catch (error) {
          reject(error);
        }
      });
    }
  }
};