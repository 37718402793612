var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-chart-box" }, [
    _c("img", {
      staticClass: "zz-img-title",
      attrs: {
        src: require("../../../assets/images/home_title1.png"),
        alt: "",
      },
    }),
    _c("div", { ref: "rankingChart", staticClass: "chart" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }