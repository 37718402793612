var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c("div", [
        _c(
          "div",
          {
            staticClass: "login-container",
            style: { backgroundImage: "url(" + _vm.bgImgUrl + ")" },
          },
          [
            _c("div", { staticClass: "right" }, [
              _c("div", { staticClass: "top-title" }, [_vm._v("欢迎使用")]),
              _c("div", { staticClass: "main-title" }, [
                _vm._v(_vm._s(_vm.platformName)),
              ]),
              _c("div", { staticClass: "sub-title" }, [
                _vm._v("数据让城市运行更科学"),
              ]),
              _c(
                "div",
                { staticClass: "box" },
                [
                  _c("el-input", {
                    ref: "loginName",
                    attrs: {
                      "prefix-icon": "el-icon-user",
                      placeholder: "请输入您的登录账号",
                    },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.perLogin.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.form.loginName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "loginName", $$v)
                      },
                      expression: "form.loginName",
                    },
                  }),
                  _c("el-input", {
                    ref: "password",
                    attrs: {
                      "prefix-icon": "el-icon-lock",
                      placeholder: "请输入您的登录密码",
                      type: "password",
                    },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.perLogin.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.form.password,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "password", $$v)
                      },
                      expression: "form.password",
                    },
                  }),
                  _c(
                    "el-button",
                    {
                      staticClass: "btn my-button",
                      attrs: { type: "primary", loading: _vm.loginLoading },
                      on: { click: _vm.onLogin },
                    },
                    [
                      _vm.loginLoading
                        ? [_vm._v(_vm._s(_vm.loginText))]
                        : [_vm._v("登录")],
                    ],
                    2
                  ),
                ],
                1
              ),
            ]),
            _c("footer", { domProps: { innerHTML: _vm._s(_vm.footer) } }),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }