var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-chart-box" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "item" }, [
        _c("div", { staticClass: "title" }, [_vm._v("销售同比")]),
        _c("div", { staticClass: "desc" }, [
          _c("img", { attrs: { src: "", alt: "" } }),
          _c("span", { staticClass: "text1" }, [
            _vm._v(_vm._s(_vm.results.lastYearRate) + "%"),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "item" }, [
        _c("div", { staticClass: "title" }, [_vm._v("销售环比")]),
        _c("div", { staticClass: "desc" }, [
          _c("img", { attrs: { src: "", alt: "" } }),
          _c("span", { staticClass: "text2" }, [
            _vm._v(_vm._s(_vm.results.lastMonthRate) + "%"),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }