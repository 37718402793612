import { render, staticRenderFns } from "./TrendIndustry.vue?vue&type=template&id=226c860e&scoped=true&"
import script from "./TrendIndustry.vue?vue&type=script&lang=js&"
export * from "./TrendIndustry.vue?vue&type=script&lang=js&"
import style0 from "./TrendIndustry.vue?vue&type=style&index=0&id=226c860e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "226c860e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/jenkins/agent/workspace/digitalnmn8f/digital-guv-web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('226c860e')) {
      api.createRecord('226c860e', component.options)
    } else {
      api.reload('226c860e', component.options)
    }
    module.hot.accept("./TrendIndustry.vue?vue&type=template&id=226c860e&scoped=true&", function () {
      api.rerender('226c860e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/trend/components/TrendIndustry.vue"
export default component.exports