import ajax from '@/utils/ajax';
/* 行业消费趋势（根据type值不同,常住/外来/各行业消费趋势） */

export function queryTrend(data) {
  return ajax({
    url: '/digital/trend/queryTrend',
    method: 'post',
    data: data
  });
}
/* 人口活跃趋势 */

export function queryActivityPerson(data) {
  return ajax({
    url: '/digital/trend/queryActivityPerson',
    method: 'post',
    data: data
  });
}