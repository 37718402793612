var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c(
        "div",
        { staticClass: "flex items-center justify-between page-header" },
        [
          _c(
            "div",
            { staticClass: "flex items-center" },
            [
              _c("DateSelect", {
                attrs: { immediate: true },
                model: {
                  value: _vm.dates,
                  callback: function ($$v) {
                    _vm.dates = $$v
                  },
                  expression: "dates",
                },
              }),
              _c("BusinessSelect", {
                staticClass: "mx-2",
                attrs: { ov: _vm.pkTradeCodes, immediate: true },
                model: {
                  value: _vm.tradeCodes,
                  callback: function ($$v) {
                    _vm.tradeCodes = $$v
                  },
                  expression: "tradeCodes",
                },
              }),
              _c("BusinessSelect", {
                attrs: { ov: _vm.tradeCodes, pkMode: true, clearable: true },
                model: {
                  value: _vm.pkTradeCodes,
                  callback: function ($$v) {
                    _vm.pkTradeCodes = $$v
                  },
                  expression: "pkTradeCodes",
                },
              }),
            ],
            1
          ),
          _c("RouterMenu"),
        ],
        1
      ),
      _c(
        "keep-alive",
        [_c(_vm.currentComponent, { ref: "component", tag: "component" })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }