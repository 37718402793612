var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "VisitorsFlowrate" }, [
    _vm._m(0),
    _c("div", { ref: "visitorsFlowrate", staticClass: "chart" }),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "legends" }, [
      _c("div", { staticClass: "legend" }, [
        _c("span", {
          staticClass: "line",
          staticStyle: { background: "#DC443F" },
        }),
        _c("span", [_vm._v("客流")]),
      ]),
      _c("div", { staticClass: "legend" }, [
        _c("span", {
          staticClass: "line",
          staticStyle: { background: "#1CC4A7" },
        }),
        _c("span", [_vm._v("人流")]),
      ]),
      _c("div", { staticClass: "legend" }, [
        _c("span", {
          staticClass: "line",
          staticStyle: { background: "#2F70D6" },
        }),
        _c("span", [_vm._v("消费者")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }