var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-chart-box" }, [
    _c("img", {
      staticClass: "title_img",
      attrs: { src: require("@/assets/images/areaConvertTitle.png"), alt: "" },
    }),
    _c(
      "div",
      { staticClass: "chart_box" },
      [
        _c(
          "el-row",
          {
            staticStyle: {
              height: "100%",
              display: "flex",
              "align-items": "stretch",
            },
            attrs: { gutter: 15 },
          },
          [
            _c(
              "el-col",
              { staticClass: "chart-col", attrs: { span: 14 } },
              [
                _c("div", { ref: "areaConvert", staticClass: "chart" }),
                _c("area-consume-trend", {
                  attrs: { searchParams: _vm.searchParams },
                }),
              ],
              1
            ),
            _c("el-col", { attrs: { span: 10 } }, [
              _c("div", { staticClass: "legends mt" }, [
                _c("div", { staticClass: "legend" }, [
                  _c("span", {
                    staticClass: "line",
                    staticStyle: { background: "#D5A938" },
                  }),
                  _c("span", [_vm._v("人流")]),
                ]),
                _c("div", { staticClass: "legend" }, [
                  _c("span", {
                    staticClass: "line",
                    staticStyle: { background: "#1CC4A7" },
                  }),
                  _c("span", [_vm._v("客流")]),
                ]),
                _c("div", { staticClass: "legend" }, [
                  _c("span", {
                    staticClass: "line",
                    staticStyle: { background: "#2F70D6" },
                  }),
                  _c("span", [_vm._v("消费者")]),
                ]),
              ]),
              _vm.consumeType
                ? _c("div", { staticClass: "coneImg_box" }, [
                    _c("img", {
                      staticClass: "title_img",
                      attrs: {
                        src: require("@/assets/images/areaConeImg.jpg"),
                        alt: "",
                      },
                    }),
                    _c("div", { staticClass: "consume_type one" }, [
                      _vm._v(_vm._s(_vm.consumeType.peopleAmount) + "%"),
                    ]),
                    _c("div", { staticClass: "consume_type two" }, [
                      _vm._v(_vm._s(_vm.consumeType.flowAmount) + "%"),
                    ]),
                    _c("div", { staticClass: "consume_type three" }, [
                      _vm._v(
                        " " + _vm._s(_vm.consumeType.consumeAmount) + "% "
                      ),
                    ]),
                  ])
                : _vm._e(),
            ]),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }