import ajax from '@/utils/ajax';
/* 商圈销售排名 */

export function queryAreaSales(data) {
  return ajax({
    url: '/digital/area/queryAreaSales',
    method: 'post',
    data: data
  });
}
/* 商圈消费转化率top5 */

export function queryAreaRate(data) {
  return ajax({
    url: '/digital/area/queryAreaRate',
    method: 'post',
    data: data
  });
}
/* 商圈周边人口top5 */

export function queryConsumeTrend(data) {
  return ajax({
    url: '/digital/trade/queryConsumeTrend',
    method: 'post',
    data: data
  });
}
/* 左下角,人流,客流,消费者折线图 */

export function queryAreaPeople(data) {
  return ajax({
    url: '/digital/area/queryAreaPeople',
    method: 'post',
    data: data
  });
}
/* 商圈消费分类占比 */

export function queryAreaBizSales(data) {
  return ajax({
    url: '/digital/area/queryAreaBizSales',
    method: 'post',
    data: data
  });
}
/* 右上角根据区code查询当前当前区域基本信息 */

export function queryAreaBaseInfo(data) {
  return ajax({
    url: '/digital/area/queryAreaBaseInfo',
    method: 'post',
    data: data
  });
}
/* 倒三角 */

export function queryConsumeType(data) {
  return ajax({
    url: '/digital/trade/queryConsumeType',
    method: 'post',
    data: data
  });
}
export function queryTradeBiz(data) {
  return ajax({
    url: '/digital/area/queryTradeBiz',
    method: 'post',
    data: data
  });
}
import pudongGeoJson from './300315.json';
/* 获取行政区级别的geojson数据 */

export function queryDistrictGeoJson(districtCode) {
  //   return ajax({
  //     url: `https://geo.datav.aliyun.com/areas_v3/bound/${districtCode}.json`,
  //     method: 'get'
  //   });
  return pudongGeoJson;
}