var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-container" }, [
    _c(
      "div",
      { staticClass: "flex items-center justify-between page-header" },
      [
        _c(
          "div",
          { staticClass: "flex items-center" },
          [
            _c("AreaSelect", {
              attrs: { clearable: true },
              on: { change: _vm.onAreaChange },
              model: {
                value: _vm.form.area,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "area", $$v)
                },
                expression: "form.area",
              },
            }),
            _c("DateSelect", {
              staticClass: "ml-2",
              attrs: { immediate: true },
              model: {
                value: _vm.form.dates,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "dates", $$v)
                },
                expression: "form.dates",
              },
            }),
          ],
          1
        ),
        _c("RouterMenu"),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "page-content" },
      [
        _c(
          "el-row",
          { staticStyle: { width: "100%" }, attrs: { gutter: 15 } },
          [
            _c(
              "el-col",
              { staticClass: "flex flex-col", attrs: { span: 14 } },
              [
                _c(
                  "el-row",
                  {
                    staticStyle: { "margin-left": "0" },
                    attrs: { gutter: 15 },
                  },
                  [
                    _c(
                      "el-col",
                      {
                        staticStyle: { "padding-left": "0" },
                        attrs: { span: 12 },
                      },
                      [_c("AreaSales", { ref: "ref1" })],
                      1
                    ),
                    _c(
                      "el-col",
                      {
                        staticStyle: { "padding-right": "0" },
                        attrs: { span: 12 },
                      },
                      [_c("AreaPopulation", { ref: "ref2" })],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [_c("AreaConvert", { ref: "ref3" })],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-col",
              {
                staticClass: "flex flex-col",
                staticStyle: { "margin-left": "0" },
                attrs: { span: 10 },
              },
              [
                _c(
                  "el-row",
                  { attrs: { gutter: 15 } },
                  [
                    _c(
                      "el-col",
                      {
                        staticStyle: { "padding-left": "0" },
                        attrs: { span: 12 },
                      },
                      [_c("AreaCategories", { ref: "ref4" })],
                      1
                    ),
                    _c(
                      "el-col",
                      {
                        staticStyle: { "padding-right": "0" },
                        attrs: { span: 12 },
                      },
                      [_c("AreaBasicInformation", { ref: "ref6" })],
                      1
                    ),
                  ],
                  1
                ),
                _c("AreaMap", { ref: "ref5" }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }