var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-container" }, [
    _c(
      "div",
      { staticClass: "flex justify-between items-center page-header" },
      [
        _c("DateSelect", {
          attrs: { immediate: true },
          model: {
            value: _vm.dates,
            callback: function ($$v) {
              _vm.dates = $$v
            },
            expression: "dates",
          },
        }),
        _c("RouterMenu"),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "page-content" },
      [
        _c(
          "el-row",
          { staticStyle: { width: "100%" }, attrs: { gutter: 15 } },
          [
            _c(
              "el-col",
              { staticClass: "flex flex-col", attrs: { span: 7 } },
              [
                _c("ConsumeStickiness", { attrs: { timeline: _vm.timeline } }),
                _c("HomeSales", { attrs: { timeline: _vm.timeline } }),
                _c("ConsumeRanking", { attrs: { timeline: _vm.timeline } }),
              ],
              1
            ),
            _c(
              "el-col",
              { staticClass: "flex flex-col", attrs: { span: 12 } },
              [
                _c("HomeMap", { attrs: { timeline: _vm.timeline } }),
                _c("ConversionRate", { attrs: { timeline: _vm.timeline } }),
              ],
              1
            ),
            _c(
              "el-col",
              { staticClass: "flex flex-col", attrs: { span: 5 } },
              [
                _c("ConsumeRatio", { attrs: { timeline: _vm.timeline } }),
                _c("TypeRatio", { attrs: { timeline: _vm.timeline } }),
                _c("BusinessRatio", { attrs: { timeline: _vm.timeline } }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }