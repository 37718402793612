var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-chart-box" }, [
    _c("img", {
      staticClass: "zz-img-title",
      attrs: {
        src: require("../../../assets/images/home_title3.png"),
        alt: "",
      },
    }),
    _vm._m(0),
    _c("div", { ref: "salesChart", staticClass: "chart" }),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "legends" }, [
      _c("div", { staticClass: "legend" }, [
        _c("span", {
          staticClass: "line",
          staticStyle: { background: "#D64342" },
        }),
        _c("span", [_vm._v("输入消费")]),
      ]),
      _c("div", { staticClass: "legend" }, [
        _c("span", {
          staticClass: "line",
          staticStyle: { background: "#18B9A2" },
        }),
        _c("span", [_vm._v("输出消费")]),
      ]),
      _c("div", { staticClass: "legend" }, [
        _c("span", {
          staticClass: "line",
          staticStyle: { background: "#F2BD2A" },
        }),
        _c("span", [_vm._v("输入环比")]),
      ]),
      _c("div", { staticClass: "legend" }, [
        _c("span", {
          staticClass: "line",
          staticStyle: { background: "#C519DB" },
        }),
        _c("span", [_vm._v("输出环比")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }