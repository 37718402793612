import ajax from '@/utils/ajax';
/* 商圈销售趋势 pk */

export function PKQuerySales(data) {
  return ajax({
    url: '/digital/pk/querySales',
    method: 'post',
    data: data
  });
}
/* pk 人流趋势比较 */

export function PKQueryPeopleTrend(data) {
  return ajax({
    url: '/digital/pk/queryPeopleTrend',
    method: 'post',
    data: data
  });
}
/* pk 商圈消费人群标签统计比较  */

export function PKQueryTag(data) {
  return ajax({
    url: '/digital/pk/queryTag',
    method: 'post',
    data: data
  });
}
/* pk 各行业交易金额占比  */

export function PKQueryTradeBizSales(data) {
  return ajax({
    url: '/digital/pk/queryTradeBizSales',
    method: 'post',
    data: data
  });
}
/* pk 各行业交易金额占比  */

export function PKQueryScore(data) {
  return ajax({
    url: '/digital/pk/queryScore',
    method: 'post',
    data: data
  });
}
/* pk 商圈刷卡来源地比较  */

export function PKQueryTradeSalesSource(data) {
  return ajax({
    url: '/digital/pk/queryTradeSalesSource',
    method: 'post',
    data: data
  });
}
/* pk 商圈共享客流比较  */

export function PKQueryPeopleShare(data) {
  return ajax({
    url: '/digital/pk/queryPeopleShare',
    method: 'post',
    data: data
  });
}
/* 商圈刷卡来源地  */

export function queryTradeSalesSource(data) {
  return ajax({
    url: '/digital/trade/queryTradeSalesSource',
    method: 'post',
    data: data
  });
}
/* 商圈客流来源地  */

export function queryTradePeopleSource(data) {
  return ajax({
    url: '/digital/trade/queryTradePeopleSource',
    method: 'post',
    data: data
  });
}
/* 商圈行业占比  */

export function queryTradeBizSales(data) {
  return ajax({
    url: '/digital/trade/queryTradeBizSales',
    method: 'post',
    data: data
  });
}
/* 商圈共享客流占比  */

export function queryPeopleShare(data) {
  return ajax({
    url: '/digital/trade/queryPeopleShare',
    method: 'post',
    data: data
  });
}
/* 商圈销售趋势  */

export function queryTread(data) {
  return ajax({
    url: '/digital/trade/queryTread',
    method: 'post',
    data: data
  });
}
/* 商圈人群标签统计  */

export function queryTag(data) {
  return ajax({
    url: '/digital/trade/queryTag',
    method: 'post',
    data: data
  });
}
/* 右上角各评分  */

export function queryScore(data) {
  return ajax({
    url: '/digital/trade/queryScore',
    method: 'post',
    data: data
  });
}
/* 销售同比，环比  */

export function queryRate(data) {
  return ajax({
    url: '/digital/trade/queryRate',
    method: 'post',
    data: data
  });
}
/* 商圈动态趋势  */

export function queryPeopleTrend(data) {
  return ajax({
    url: '/digital/trade/queryPeopleTrend',
    method: 'post',
    data: data
  });
}
/* 主要商业体  */

export function queryTradeBody(data) {
  return ajax({
    url: '/digital/trade/queryTradeBody',
    method: 'post',
    data: data
  });
}