var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isShow,
          expression: "isShow",
        },
      ],
      staticClass: "page-chart-box",
    },
    [
      _c("img", {
        staticClass: "title_img",
        attrs: {
          src: require("@/assets/images/convenientStaticsTitle.png"),
          alt: "",
        },
      }),
      _c(
        "div",
        { staticClass: "box" },
        [
          _vm._m(0),
          _vm._l(_vm.list, function (item, index) {
            return _c("div", { key: item.id, staticClass: "item" }, [
              _c("span", { style: { color: _vm.getColor(index) } }, [
                _vm._v(" " + _vm._s(item.name) + " "),
              ]),
              _c("span", [_vm._v(" " + _vm._s(item.count) + " ")]),
              _c("span", [_vm._v(" " + _vm._s(item.density) + " ")]),
            ])
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "item" }, [
      _c("span"),
      _c("span", [_vm._v("总数")]),
      _c("span", [_vm._v("密度(每平方公里)")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }