var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-chart-box" }, [
    _c("img", {
      staticClass: "title_img",
      attrs: { src: require("@/assets/images/trendForeignTitle.png"), alt: "" },
    }),
    _c("div", {
      ref: "trendForeign",
      staticClass: "chart",
      style: { visibility: _vm.isShowChart ? "visible" : "hidden" },
    }),
    _c(
      "div",
      {
        staticClass: "chart-empty-title",
        style: { visibility: _vm.isShowChart ? "hidden" : "visible" },
      },
      [_vm._v(" 暂无数据 ")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }