var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-chart-box" }, [
    _c("img", {
      staticClass: "title_img",
      attrs: {
        src: require("@/assets/images/areaPopulationTitle.png"),
        alt: "",
      },
    }),
    _vm._m(0),
    _c("div", { ref: "areaPopulation", staticClass: "chart" }),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "legends" }, [
      _c("div", { staticClass: "legend" }, [
        _c("span", {
          staticClass: "line",
          staticStyle: { background: "#1CC4A7" },
        }),
        _c("span", [_vm._v("人口")]),
      ]),
      _c("div", { staticClass: "legend" }, [
        _c("span", {
          staticClass: "line",
          staticStyle: { background: "#2F70D6" },
        }),
        _c("span", [_vm._v("密度(人/平方公里)")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }