import Login from '@/views/users/login';
import NotFound from '@/views/others/404.vue';
import Home from '@/views/home/index';
import Area from '@/views/area/index';
import Business from '@/views/business/index';
import Convenient from '@/views/convenient/index';
import Trend from '@/views/trend/index';
import Test from '@/views/business/pk/index';
import cache from '@/utils/cache';
import Layout from '@/layout';
export default [{
  path: '/login',
  name: 'redirectLogin',
  redirect: function redirect() {
    var tenantCode = cache.getTenantCode();
    return {
      path: "/".concat(tenantCode, "/login")
    };
  }
}, {
  path: '/:tenantCode/login',
  name: 'login',
  meta: {
    title: '登录',
    hideInMenu: true
  },
  component: Login
}, {
  path: '/',
  // 切换成history的话，需要这个配置
  // FIXME:上线前修正
  redirect: '/app/home'
}, {
  path: '/app',
  name: 'App',
  component: Layout,
  children: [{
    path: 'home',
    name: 'Home',
    component: Home
  }, {
    path: 'area',
    name: 'Area',
    component: Area
  }, {
    path: 'business',
    name: 'Business',
    component: Business
  }, {
    path: 'convenient',
    name: 'Convenient',
    component: Convenient
  }, {
    path: 'trend',
    name: 'Trend',
    component: Trend
  }, {
    path: 'test',
    name: 'Test',
    component: Test
  }]
}, {
  path: '/404',
  component: NotFound
}, {
  path: '*',
  redirect: '/404'
}]; //
// const createRouter = () => new Router({
//   mode: 'history', // require service support
//   routes: constantRoutes
// });
//
// // router.afterEach(to => {
// //   window.scrollTo(0, 0);
// // });
//
// const router = createRouter();
//
// export default router;